import { destroy } from 'redux-form/immutable';
import { Map as IMap } from 'immutable';
import { delay } from 'redux-saga';
import {
    take, put, select, call, race, takeLatest
} from 'redux-saga/effects';
import {
    placeOrder,
    ORDER_SUCCESS,
    ORDER_FAILED,
    PAYMENT_FAILED,
    fetchInitialPaymentConfigData,
    RETRIEVED_INITIATE_CONFIG_DATA,
    REQUEST_INITIATE_CONFIG_ERROR,
    clearCapiSession
} from 'capi/redux';
import { getCapiSessionID } from 'capi/redux/immutable';

import { resetIovation } from '../iovation/iovationModule';
import { itemReset } from '../item/itemModule';
import {
    getPromoItems,
    getPaidDigitalItems,
    getShippingGroups,
    getSubTotal,
    getShippingTotal,
    getActiveItems,
    getUYOPQuantity
} from '../item/itemSelectors';
import { resetPromo, clearRejectedPromos } from '../promo/promoModule';
import { resetLoyalty } from '../loyalty/loyaltyModule';
import { getStorefrontSessionKey } from '../session/sessionSelectors';
import { parseOrderCompleted } from '../segment/segmentSelectors';
import { logError, logWarning } from '../utils/errorUtils';
import {
    getDiscountRewardAmount,
    getPromoState,
    getCustomRewardCardRecipients,
    getCartQualifies,
    getShippingPromoData
} from '../promo/promoSelectors';
import {
    getOrCreateStorefrontSession,
    clearStorefrontSession,
    logSessionInfoInSegment
} from '../session/sessionModule';
import { bundlePurchase, orderComplete, showAppError } from '../routing/flowModule';
import { APP_ERROR_TYPES } from '../routing/flow';
import { resetPreferredPaymentValues } from '../routing/flowProgressModule';
import {
    resetCardholderAgreementAcceptance,
    resetGdprTermsAgreementAcceptance
} from '../consent/consentModule';

import { PAYMENT_FORM_NAME } from './paymentConstants';
import { orderSerializer, orderResponseSerializer, sanitizePaymentData } from './capiOrderV4ItemSerializer';
import {
    getActivationFeesCount,
    getConfirmationUrlParams,
    getConsumerFees,
    getGrandTotal,
    orderMetaData
} from './paymentSelectors';
import {
    paymentMethodDataHandlers,
    paymentFailed,
    startSendOrder,
    paymentSuccess,
    paymentReset,
    PROCESS_PAYMENT,
    PROCESS_INITIATE_CONFIG,
    initiateConfigRequestSuccess,
    initiateConfigRequestFailed,
    trackPaymentPerformance
} from './paymentModule';
import { getShippingProfiles } from '../shipping/shippingSelectors';
import { getRequireRecipientEmailForPhysicalGift, getBrandName } from '../brand/brandSelectors';
import { segmentCallForBundles } from '../bundles/bundlesUtils';
import { PAYMENT_PLUS_PAYMENT_TYPE } from '../paymentJS/paymentPlusSerializers';

import { newItemCPFNumber } from '../item/newItemForm';

export const defaultFailureResponse = {
    status: 402,
    statusText: 'Payment Required',
    data: {
        errorCode: 'PAYMENT_REQUIRED',
        fieldErrors: []
    }
};

export const parseErrorResponse = response => ({
    httpCode: response.status,
    httpMessage: response.statusText,
    capiCode: response.data.errorCode,
    fieldErrors: response.data.fieldErrors
});


export function* handleSessionExpiry(orderResponse) {
    if (orderResponse.capiCode === 'SESSION_EXPIRED') {
        const storefrontSessionKey = yield select(getStorefrontSessionKey);
        const sessionId = yield select(getCapiSessionID);
        const additionalDataToLog = { storefrontSessionKey, sessionId };

        logWarning(
            'session has expired due to: payment already completed or session id not passed',
            additionalDataToLog
        );
        yield put(logSessionInfoInSegment('Order failed with session expiry', additionalDataToLog));

        yield put(clearStorefrontSession());
        yield put(clearCapiSession());
        yield put(getOrCreateStorefrontSession());
    }
}


export function* orderingSaga(payment) {
    const metaData = yield select(orderMetaData);
    const digitalItems = yield select(getPaidDigitalItems);
    const shippingGroups = yield select(getShippingGroups);
    const requireRecipientEmailForPhysicalGift = yield select(getRequireRecipientEmailForPhysicalGift);
    const customRewardCardRecipients = yield select(getCustomRewardCardRecipients);
    const fees = yield select(getConsumerFees);
    const cpfNumber = yield select(newItemCPFNumber);
    const cartQualifies = yield select(getCartQualifies);
    const shippingPromoData = yield select(getShippingPromoData);
    const order = yield call(orderSerializer,
        payment,
        metaData,
        digitalItems,
        shippingGroups,
        requireRecipientEmailForPhysicalGift,
        customRewardCardRecipients,
        fees,
        cpfNumber,
        cartQualifies,
        shippingPromoData);

    const paymentMethod = payment.get('paymentMethod');
    const performanceTrackingData = paymentMethod === PAYMENT_PLUS_PAYMENT_TYPE ? {
        paymentType: paymentMethod,
        paymentMethod: payment.get('paymentData').get('response', IMap()).get('paymentMethod', null)
    } : {
        paymentType: 'Native',
        paymentMethod
    };
    
    yield put(trackPaymentPerformance('order_api::started', performanceTrackingData));
    
    yield put(placeOrder(order.toJS()));
    
    const result = yield take([
        ORDER_SUCCESS, ORDER_FAILED, PAYMENT_FAILED
    ]);

    
    
    
    
    payment = sanitizePaymentData(payment);

    
    
    yield put(trackPaymentPerformance('order_api::completed', performanceTrackingData));

    switch (result.type) {
        case ORDER_FAILED: {
            logWarning(result.error);
            const res = typeof result.error.response !== 'undefined'
                ? result.error.response : defaultFailureResponse;
            if (res.status === 504) {
                
                
                
                yield put(showAppError(APP_ERROR_TYPES.NO_RETRY));

                
                
                yield put(logSessionInfoInSegment('Gateway timeout from CAPI'));
            } else {
                const parsedResponse = parseErrorResponse(res);
                yield call(handleSessionExpiry, parsedResponse);
                yield put(paymentFailed(parsedResponse));
            }
            break;
        }
        case PAYMENT_FAILED: {
            yield call(paymentErrorHandler, result.error, payment, payment.get('paymentMethod'));
            break;
        }
        case ORDER_SUCCESS: {
            
            
            
            try {
                const orderedItems = yield select(getActiveItems);
                
                
                
                yield put(paymentSuccess(result.data));

                const promoItems = yield select(getPromoItems);
                const orderData = yield call(orderResponseSerializer, result.data);
                yield put(clearRejectedPromos());
                const promoState = yield select(getPromoState);
                const shippingMethods = yield select(getShippingProfiles);
                const orderDetails = {
                    brandName: yield select(getBrandName),
                    orderData: orderData.toJS(),
                    items: orderedItems.toJS(),
                    promoItems: promoItems.toJS(),
                    subtotal: parseFloat((yield select(getSubTotal)).toFixed(2)),
                    grandTotal: parseFloat((yield select(getGrandTotal)).toFixed(2)),
                    shipping: yield select(getShippingTotal),
                    sessionID: yield select(getStorefrontSessionKey),
                    paymentData: payment.set('purchaseDate', new Date()).toJS(),
                    totalDiscountRewardAmount: yield select(getDiscountRewardAmount),
                    uyoPlasticCount: yield select(getUYOPQuantity),
                    activationFeesCardsCount: yield select(getActivationFeesCount),
                    promo: promoState.toJS(),
                    shippingMethods: shippingMethods.toJS(),
                    paymentUrlParams: yield select(getConfirmationUrlParams)
                };
                const segmentOrderCompletedData = yield select(
                    parseOrderCompleted,
                    orderData.get('orderNumber'),
                    metaData.get('currencyCode')
                );

                
                
                yield call(segmentCallForBundles, orderedItems, bundlePurchase);

                yield put(orderComplete(orderDetails, segmentOrderCompletedData));
            } catch (e) {
                
                yield put(showAppError(APP_ERROR_TYPES.NO_RETRY));
                logError(`Order success handling failed: "${e}", bailing and showing the user a 500`);
            } finally {
                
                
                yield put(itemReset());
                yield put(destroy(PAYMENT_FORM_NAME));
                yield put(paymentReset());
                yield put(resetIovation());
                yield put(resetPromo());
                yield put(resetLoyalty());
                yield put(resetCardholderAgreementAcceptance());
                yield put(resetGdprTermsAgreementAcceptance());
                yield put(resetPreferredPaymentValues());
            }
            break;
        }
        default:
            break;
    }
}


export function* paymentErrorHandler(error, payment, paymentMethod) {
    logWarning(`${paymentMethod} auth failed: ${error}`, payment);
    const parsedResponse = parseErrorResponse(defaultFailureResponse);
    yield put(paymentFailed(parsedResponse));
}

export function* initiateConfigWatcherSaga() {
    yield takeLatest(PROCESS_INITIATE_CONFIG, function* initiateConfigSaga(action) {
        const { data } = action;
        
        yield put(fetchInitialPaymentConfigData(data.toJS()));
        
        const result = yield take([
            RETRIEVED_INITIATE_CONFIG_DATA, REQUEST_INITIATE_CONFIG_ERROR
        ]);
        switch (result.type) {
            case RETRIEVED_INITIATE_CONFIG_DATA:
                yield put(initiateConfigRequestSuccess(result.data));
                break;
            case REQUEST_INITIATE_CONFIG_ERROR:
                
                logError('Error on initiate config call. Capi-js returned a request failure');
                
                yield put(initiateConfigRequestFailed());
                yield put(trackPaymentPerformance('initiate-config::failed', result.error));
                break;
            default:
                break;
        }
    });
}


export function* placeOrderSaga() {
    
    while (true) {
        
        
        
        
        let { paymentMethod, paymentData } = yield take(PROCESS_PAYMENT);

        
        yield put(startSendOrder());

        try {
            const paymentMethodDataHandler = paymentMethodDataHandlers.get(paymentMethod);
            let payment = yield call(paymentMethodDataHandler, paymentData);

            
            if (payment.get('approved')) {
                
                
                
                const ORDERING_TIMEOUT_THRESHOLD = 30; 
                const { timeout, response } = yield race({
                    timeout: delay(ORDERING_TIMEOUT_THRESHOLD * 1000),
                    response: call(orderingSaga, payment)
                });

                if (timeout && !response) {
                    logWarning('Order placement timeout');
                    
                    
                    
                    yield put(showAppError(APP_ERROR_TYPES.NO_RETRY));

                    
                    
                    yield put(logSessionInfoInSegment('Order placement timeout'));
                }
            } else {
                const paymentResponse = payment.get('response');
                payment = sanitizePaymentData(payment);
                yield call(paymentErrorHandler, paymentResponse, payment, paymentMethod);
            }
        } catch (e) {
            
            
            
            
            paymentData = sanitizePaymentData(paymentData);
            yield call(paymentErrorHandler, e, paymentData, paymentMethod);
        }
    }
}

export default placeOrderSaga;
